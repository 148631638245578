import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
loggedUser = [];

  constructor( private storageService: StorageService) { }

  login() {
    this.isAuthenticated.next(true);
    // console.log(this.isAuthenticated);
  }

  async logout() {
    this.loggedUser = await this.storageService.getData('user');
      // console.log(this.loggedUser.length);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for(let i = 0; i < this.loggedUser.length; i++) {
      await this.storageService.removeItem('user', 0);
    }
    // console.log('logout');
    this.isAuthenticated.next(false);
    // console.log(this.isAuthenticated.value);
  }
}

