import { Injectable } from '@angular/core';
import { CanActivate, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), //filter out initial behaviour subject value
      take(1),
      map(isAuthenticated => {
        if(isAuthenticated) {
          // console.log('Auth true', isAuthenticated);
          return true;
        }
        else {
          // console.log('Auth false', isAuthenticated);
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }
}
