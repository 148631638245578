import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'wahlerstellen',
    loadChildren: () => import('./pages/wahleneb/wahleneb.module').then( m => m.WahlenebPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'wahlen',
  //   loadChildren: () => import('./pages/wahlen/wahlen.module').then( m => m.WahlenPageModule),
  // },
  {
    path: 'wahlbearbeiten/:wid',
    loadChildren: () => import('./pages/wahleneb/wahleneb.module').then( m => m.WahlenebPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profilanzeigenmodal',
    loadChildren: () => import('./pages/profilanzeigenmodal/profilanzeigenmodal.module').then( m => m.ProfilanzeigenmodalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kandidaterstellen',
    loadChildren: () => import('./pages/kandidateneb/kandidateneb.module').then( m => m.KandidatenebPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kandidatbearbeiten/:kaid',
    loadChildren: () => import('./pages/kandidateneb/kandidateneb.module').then( m => m.KandidatenebPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kandidatendetails/:kaid',
    loadChildren: () => import('./pages/kandidatendetails/kandidatendetails.module').then( m => m.KandidatendetailsPageModule)
  },
  {
    path: 'wahlergebnisdetails/:wid',
    loadChildren: () => import('./pages/wahlergebnissedetails/wahlergebnissedetails.module').then( m => m.WahlergebnissedetailsPageModule)
  },
  {
    path: 'voting/:wid',
    loadChildren: () => import('./pages/voting/voting.module').then( m => m.VotingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'passwortaendern/:pid',
    loadChildren: () => import('./pages/passwortaendern/passwortaendern.module').then( m => m.PasswortaendernPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'passwortreset',
    loadChildren: () => import('./pages/passwortreset/passwortreset.module').then( m => m.PasswortresetPageModule)
  },
  {
    path: 'wahlleitermodal',
    loadChildren: () => import('./pages/wahlleitermodal/wahlleitermodal.module').then( m => m.WahlleitermodalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'beispielwahlentenhausen',
    loadChildren: () => import('./pages/bspwahl/bspwahl.module').then( m => m.BspwahlPageModule)
  },
  {
    path: 'bilderhinzufuegen',
    loadChildren: () => import('./pages/bilderhinzufuegen/bilderhinzufuegen.module').then( m => m.BilderhinzufuegenPageModule)
  },
  {
    path: 'passwortset/:pid',
    loadChildren: () => import('./pages/passwortset/passwortset.module').then( m => m.PasswortsetPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
