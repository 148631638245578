import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// const STORAGE_KEY_USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage) { }

  async init() {
    // console.log('INIT');
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    // console.log('DONE');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  getData(STORAGE_KEY) {
    // console.log('GET DATA');
    return this.storage.get(STORAGE_KEY) || [];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async addData(STORAGE_KEY, item) {
    // console.log('ADD DATA');
    const storedData = await this.storage.get(STORAGE_KEY) || [];
    storedData.push(item);
    return this.storage.set(STORAGE_KEY, storedData);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async removeItem(STORAGE_KEY, index) {
    // console.log('REMOVE DATA');
    const storedData = await this.storage.get(STORAGE_KEY) || [];
    storedData.splice(index, 1);
    return this.storage.set(STORAGE_KEY, storedData);
  }

  // clear() {
  //   console.log('CLEAR DATA');
  //   this.storage.clear();
  // }
}
